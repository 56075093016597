// @desc - Retinaディスプレイ用メディアクエリを挿入します。
// @example scss - Usage
// .hoge{
//   font-size:16px;
//   @include screen_retina{
//     font-size:14px;
//   }
// }
// @example css - CSS output
// .hoge {
//   font-size: 16px;
// }
// @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6 / 2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
//   .hoge {
//     font-size: 14px;
//   }
// }
@mixin _mq-retina {
    @media (min--moz-device-pixel-ratio: 1.3), (-o-min-device-pixel-ratio: 2.6/2), (-webkit-min-device-pixel-ratio: 1.3), (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
        @content;
    }
}
