@charset "UTF-8";
/*normal*/
.tp-news {
  padding-top: 9.5rem; }
  .tp-news-wrap {
    width: 100%;
    margin-top: 1.5rem; }
  .tp-news__btn {
    margin: 3.9rem auto 0; }
  .tp-news-contents {
    width: 100%;
    margin-top: 0; }
  @media screen and (max-width: 767px) {
    .tp-news {
      padding-top: 7.5rem; }
      .tp-news-wrap {
        width: 100%;
        margin-top: 2.8rem; }
      .tp-news__btn {
        margin: 3.2rem auto 0; }
      .tp-news-contents {
        width: 100%; } }

.tp-facebook {
  margin-top: 5.5rem;
  width: 40.0rem; }
  .tp-facebook-wrap {
    width: 40.0rem;
    height: 39.5rem;
    margin-top: 2.8rem; }
  .tp-facebook .facebook-wrapper {
    width: 100%;
    height: 39.5rem;
    margin-top: 2.1rem;
    overflow: hidden; }
  .tp-facebook__btn {
    width: 50rem;
    height: auto;
    margin: 5rem auto 0;
    border: 1px solid #666; }
    .tp-facebook__btn a {
      display: block; }
      .tp-facebook__btn a:hover, .tp-facebook__btn a:active, .tp-facebook__btn a:focus {
        opacity: 0.6; }
  @media screen and (max-width: 767px) {
    .tp-facebook {
      width: 100%;
      margin-top: 4.5rem; }
      .tp-facebook-wrap {
        width: 100%;
        height: 40.2rem;
        margin-top: 2.0rem; }
      .tp-facebook .facebook-wrapper {
        height: 40.2rem;
        margin-top: 2.2rem; }
      .tp-facebook__btn {
        width: 100%;
        margin: 3.5rem auto 0; } }

.tp-active {
  padding: 9.5rem 0 0; }
  @media screen and (max-width: 767px) {
    .tp-active {
      padding: 5rem 0 0; } }

.tp-active-report {
  width: 100%;
  height: 20rem;
  background-image: linear-gradient(90deg, #54c2f0 0%, #004097 100%, #338fcd 50.2%);
  padding: 2rem 2rem 2rem 4.2rem; }
  .tp-active-report__tit {
    color: #fff;
    font-size: 4.8rem;
    font-weight: 800;
    letter-spacing: 0.06em;
    margin-top: 1.9rem; }
  .tp-active-report__imgarea {
    display: flex;
    justify-content: center; }
    .tp-active-report__imgarea .image {
      width: 18.0rem;
      height: 16.0rem; }
      .tp-active-report__imgarea .image + .image {
        margin-left: 2.0rem; }
  .tp-active-report__btn {
    margin: 3.1rem auto 0; }
  .tp-active-report__nendo {
    font-size: 1.6rem;
    letter-spacing: 0.06em;
    text-align: center;
    margin-top: 2rem; }
    .tp-active-report__nendo a {
      color: #00479d;
      position: relative;
      padding: 0.2rem 1.5em 0.2rem 0.2rem; }
      .tp-active-report__nendo a::before {
        content: '＞';
        position: absolute;
        right: 0.1rem;
        top: 50%;
        transform: translateY(-50%); }
      .tp-active-report__nendo a::after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #00479d;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        transition: 0.4s ease; }
      .tp-active-report__nendo a:hover::after, .tp-active-report__nendo a:active::after, .tp-active-report__nendo a:focus::after {
        opacity: 0; }
  @media screen and (max-width: 767px) {
    .tp-active-report {
      height: auto;
      padding: 2.5rem; }
      .tp-active-report__tit {
        font-size: 3.0rem;
        margin-top: 2.7rem;
        text-align: center;
        letter-spacing: 0.05em; }
      .tp-active-report__imgarea {
        justify-content: center; }
        .tp-active-report__imgarea .image {
          width: 15.5rem;
          height: 13.7rem; }
          .tp-active-report__imgarea .image + .image {
            margin-left: 1.5rem; } }

.tp-movie-wrap {
  width: 100%;
  padding-top: 6.2rem;
  padding-bottom: 6.2rem;
  background-color: #c2e7fa; }
  @media screen and (max-width: 767px) {
    .tp-movie-wrap {
      padding-top: 2.5rem;
      padding-bottom: 2.5rem; } }

.tp-movie {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  position: relative;
  /*.swiper-pagination-bullet {
        margin: 0 1.1rem 0 !important;
    }
    .swiper-pagination-bullet-active {
        background-color: #000;
    }*/ }
  .tp-movie::before, .tp-movie::after {
    content: '';
    width: 1.5rem;
    height: 105%;
    background-color: #c2e7fa;
    position: absolute;
    top: -2.5%;
    z-index: 20; }
  .tp-movie::before {
    left: 0; }
  .tp-movie::after {
    right: 0; }
  .tp-movie .item {
    width: 48.4rem;
    height: 27.3rem;
    padding: 0 1.5rem;
    box-sizing: content-box; }
    .tp-movie .item iframe {
      width: 100%;
      height: 100%; }
  @media screen and (min-width: 768px) {
    .tp-movie .slick-track {
      display: flex; } }
  .tp-movie .slick-dots {
    bottom: 0; }
    .tp-movie .slick-dots li {
      width: 2.0rem;
      height: 2.0rem;
      margin: 0 0.5rem; }
  .tp-movie .slick-dots li button::before {
    font-size: 1.0rem;
    line-height: 2.0rem;
    width: 2.0rem;
    height: 2.0rem; }
  .tp-movie__btn {
    margin: 3.0rem auto 0; }
  @media screen and (max-width: 767px) {
    .tp-movie {
      margin-top: 0rem; }
      .tp-movie .item {
        width: 32.5rem;
        height: 18.3rem;
        padding: 0 1.5rem;
        box-sizing: content-box; }
      .tp-movie .slick-dots {
        bottom: 0; }
      .tp-movie__btn {
        margin: 2.0rem auto 0; } }

.tp-profile {
  padding-top: 10.5rem;
  padding-bottom: 9.5rem; }
  @media screen and (max-width: 767px) {
    .tp-profile {
      padding-top: 7.0rem;
      padding-bottom: 6.7rem; } }

.tp-profile-btn {
  width: 100%;
  color: #00479d;
  font-size: 3.0rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  text-align: center;
  padding: 2.4rem 0 2.6rem;
  border: 2px solid #00479d;
  position: relative;
  margin-top: 5rem;
  cursor: pointer; }
  .tp-profile-btn::after {
    content: '';
    width: 3.2rem;
    height: 3.2rem;
    background: url("../../assets/img/top/icon_profile_btn.svg") center center/contain no-repeat;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    right: 3.6rem;
    transition: transform 0.4s ease; }
  .tp-profile-btn.isActive::after {
    transform: rotate(180deg); }
  @media screen and (max-width: 767px) {
    .tp-profile-btn {
      font-size: 2.0rem;
      padding: 1.4rem 0 1.4rem;
      margin-top: 4.5rem; }
      .tp-profile-btn::after {
        width: 1.8rem;
        height: 1.8rem;
        right: 1.3rem; } }

.tp-profile-detail-wrap {
  margin-top: 4.0rem;
  border-bottom: 1px solid #000;
  padding-bottom: 5.0rem; }
  @media screen and (max-width: 767px) {
    .tp-profile-detail-wrap {
      margin-top: 5.0rem;
      padding-bottom: 2.7rem; } }

@media screen and (min-width: 768px) {
  .tp-profile-detail.left {
    width: 45%;
    float: left; }
  .tp-profile-detail.right {
    width: 45%;
    float: right;
    margin-top: -0.4rem; } }

.tp-profile-detail__tit {
  display: flex;
  align-items: center; }
  .tp-profile-detail__tit .main {
    color: #00479d;
    font-size: 2.2rem;
    font-weight: bold;
    letter-spacing: 0.1em; }
  .tp-profile-detail__tit .sub {
    font-size: 1.6rem;
    letter-spacing: 0.1em; }

.tp-profile-detail__txt {
  margin-top: 3.5rem; }

.tp-profile-detail__things {
  padding-left: 5em; }
  .tp-profile-detail__things .thing {
    margin-left: -5em; }

.tp-profile-detail .c-text b {
  font-weight: normal;
  color: #00479d; }

@media screen and (max-width: 767px) {
  .tp-profile-detail.right {
    margin-top: 2em; }
  .tp-profile-detail__tit {
    display: block;
    padding-bottom: 2.2rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5); }
    .tp-profile-detail__tit .main {
      font-size: 1.8rem;
      display: block; }
    .tp-profile-detail__tit .sub {
      font-size: 1.4rem;
      display: block;
      margin-top: 1.0rem; }
  .tp-profile-detail__txt {
    margin-top: 1.8rem; } }

.tp-profile-head {
  margin-top: 10.8rem; }
  .tp-profile-head__txtarea {
    width: 62.7rem; }
    .tp-profile-head__txtarea .p-yellow-unbar-tit {
      font-size: 6.4rem; }
  .tp-profile-head__txt {
    margin-top: 5.5rem;
    text-align: justify; }
  .tp-profile-head__img {
    width: 32.0rem;
    height: 32.0rem;
    border: 0.4rem solid #00479d;
    border-radius: 50%;
    overflow: hidden;
    margin-top: -0.5rem; }
  .tp-profile-head .c-text b {
    font-weight: bold;
    color: #00479d; }
  @media screen and (max-width: 767px) {
    .tp-profile-head {
      margin-top: 4rem; }
      .tp-profile-head__txtarea {
        width: 100%;
        margin-top: 3.2rem; }
        .tp-profile-head__txtarea .p-yellow-unbar-tit {
          font-size: 3.2rem; }
      .tp-profile-head__txt {
        margin-top: 3rem; }
      .tp-profile-head__img {
        width: 20.0rem;
        height: 20.0rem;
        margin: 0 auto; } }

.tp-profile-contents {
  margin-top: 6.0rem; }
  .tp-profile-contents .c-text b {
    font-weight: bold;
    color: #00479d; }
  @media screen and (max-width: 767px) {
    .tp-profile-contents {
      margin-top: 4.0rem; } }

.tp-profile-foot {
  margin-top: 9rem; }
  .tp-profile-foot__img {
    width: 100%; }
  @media screen and (max-width: 767px) {
    .tp-profile-foot {
      margin-top: 4.4rem; }
      .tp-profile-foot__img {
        margin-left: -2.5rem;
        margin-right: -2.5rem;
        width: 37.5rem; } }

.tp-vision {
  padding-top: 9.5rem;
  padding-bottom: 9.5rem; }
  .tp-vision .c-text b {
    font-weight: bold;
    color: #00479d; }
  .tp-vision__dlbtn {
    margin: 7rem auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem; }
  @media screen and (max-width: 767px) {
    .tp-vision {
      padding-top: 5rem; }
      .tp-vision__dlbtn {
        margin: 2rem auto 0; } }

.tp-vision-tit {
  color: #00479d;
  font-size: 3.2rem;
  line-height: 4.2rem;
  font-weight: bold;
  letter-spacing: 0.05em;
  padding-left: 5rem; }
  .tp-vision-tit .num {
    font-size: 6.4rem;
    color: #f7931e;
    vertical-align: middle;
    margin-left: -5rem;
    padding-right: 1.5rem; }
  .tp-vision-tit .title {
    vertical-align: middle; }
    .tp-vision-tit .title b {
      color: #398c5e; }
  .tp-vision-tit + p {
    margin-top: 3.5rem; }
  @media screen and (max-width: 767px) {
    .tp-vision-tit {
      font-size: 2.4rem;
      line-height: 3.6rem;
      padding-left: 4rem; }
      .tp-vision-tit .num {
        font-size: 4.8rem;
        margin-left: -4rem; }
      .tp-vision-tit + p {
        margin-top: 2.5rem; } }

.tp-vision-list-wrap {
  margin-top: 4.6rem; }
  @media screen and (max-width: 767px) {
    .tp-vision-list-wrap {
      margin-top: 3.0rem; } }

.tp-vision-list {
  font-size: 2.4rem;
  font-weight: bold;
  line-height: 4.0rem;
  counter-reset: number 0;
  border-bottom: 1px solid #00479d; }
  .tp-vision-list li {
    position: relative;
    padding: 0.95rem 0 0.95rem 3.4rem;
    border-top: 1px solid #00479d; }
    .tp-vision-list li b {
      color: #00479d; }
  .tp-vision-list li:before {
    content: '';
    width: 2.4rem;
    height: 2.4rem;
    position: absolute;
    left: 0;
    top: 1.7rem; }
  @media screen and (max-width: 767px) {
    .tp-vision-list {
      font-size: 1.8rem;
      line-height: 3.0rem; }
      .tp-vision-list li {
        padding: 0.7rem 0 0.7rem 2.5rem; }
      .tp-vision-list li:before {
        width: 1.8rem;
        height: 1.8rem;
        top: 1.3rem; } }
  .tp-vision-list li:nth-child(1):before {
    background: url("../../assets/img/top/num01.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(2):before {
    background: url("../../assets/img/top/num02.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(3):before {
    background: url("../../assets/img/top/num03.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(4):before {
    background: url("../../assets/img/top/num04.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(5):before {
    background: url("../../assets/img/top/num05.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(6):before {
    background: url("../../assets/img/top/num06.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(7):before {
    background: url("../../assets/img/top/num07.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(8):before {
    background: url("../../assets/img/top/num08.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(9):before {
    background: url("../../assets/img/top/num09.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(10):before {
    background: url("../../assets/img/top/num10.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(11):before {
    background: url("../../assets/img/top/num11.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(12):before {
    background: url("../../assets/img/top/num12.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(13):before {
    background: url("../../assets/img/top/num13.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(14):before {
    background: url("../../assets/img/top/num14.svg") center center/contain no-repeat; }
  .tp-vision-list li:nth-child(15):before {
    background: url("../../assets/img/top/num15.svg") center center/contain no-repeat; }

.tp-vision-contents {
  position: absolute;
  top: 39rem;
  left: 0;
  display: none;
  z-index: -1;
  width: 100%; }
  @media screen and (max-width: 767px) {
    .tp-vision-contents {
      top: 37rem; } }

.tp-pannel {
  background-image: linear-gradient(90deg, #54c2f0 0%, #004097 100%, #338fcd 50.2%);
  padding: 7.0rem 0; }
  @media screen and (max-width: 767px) {
    .tp-pannel {
      padding: 2.5rem 0; } }

.tp-pannel-contents {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.tp-pannel-card {
  width: 47.5rem;
  height: 20.0rem;
  padding: 2rem 3.3rem 2.8rem;
  display: flex;
  flex-flow: column;
  align-items: flex-end; }
  .tp-pannel-card__tit {
    width: 26.0rem;
    color: #00479d;
    font-family: "Hiragino Sans", "Hiragino Kaku Gothic ProN", "游ゴシック", YuGothic, Meiryo, sans-serif;
    font-size: 3.0rem;
    font-weight: 800;
    height: 8.4rem;
    line-height: 4.2rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .tp-pannel-card__tit b {
      color: #398c5e; }
  .tp-pannel-card__btn {
    margin-top: 1.8rem; }
  @media screen and (min-width: 1366px) {
    .tp-pannel-card.seminar {
      background: url("../../assets/img/top/bnr_seminar_pc@2x.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 1365px) {
    .tp-pannel-card.seminar {
      background: url("../../assets/img/top/bnr_seminar_pc.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 767px) {
    .tp-pannel-card.seminar {
      background: url("../../assets/img/top/bnr_seminar_pc@2x.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 499px) {
    .tp-pannel-card.seminar {
      background: url("../../assets/img/top/bnr_seminar_pc.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 499px) and (min--moz-device-pixel-ratio: 1.3), screen and (max-width: 499px) and (-o-min-device-pixel-ratio: 2.6 / 2), screen and (max-width: 499px) and (-webkit-min-device-pixel-ratio: 1.3), screen and (max-width: 499px) and (min-device-pixel-ratio: 1.3), screen and (max-width: 499px) and (min-resolution: 1.3dppx) {
    .tp-pannel-card.seminar {
      background: url("../../assets/img/top/bnr_seminar_pc@2x.jpg") center center/cover no-repeat; } }
  @media screen and (min-width: 1366px) {
    .tp-pannel-card.support {
      background: url("../../assets/img/top/bnr_support_pc@2x.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 1365px) {
    .tp-pannel-card.support {
      background: url("../../assets/img/top/bnr_support_pc.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 767px) {
    .tp-pannel-card.support {
      background: url("../../assets/img/top/bnr_support_pc@2x.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 499px) {
    .tp-pannel-card.support {
      background: url("../../assets/img/top/bnr_support_pc.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 499px) and (min--moz-device-pixel-ratio: 1.3), screen and (max-width: 499px) and (-o-min-device-pixel-ratio: 2.6 / 2), screen and (max-width: 499px) and (-webkit-min-device-pixel-ratio: 1.3), screen and (max-width: 499px) and (min-device-pixel-ratio: 1.3), screen and (max-width: 499px) and (min-resolution: 1.3dppx) {
    .tp-pannel-card.support {
      background: url("../../assets/img/top/bnr_support_pc@2x.jpg") center center/cover no-repeat; } }
  @media screen and (max-width: 767px) {
    .tp-pannel-card {
      width: 100%;
      height: 13.6rem;
      padding: 1.3rem 1rem 1rem 1rem; }
      .tp-pannel-card + .tp-pannel-card {
        margin-top: 2.5rem; }
      .tp-pannel-card__tit {
        width: 20rem;
        font-size: 2.0rem;
        line-height: 2.8rem;
        height: 5.6rem; }
      .tp-pannel-card__btn {
        width: 20rem;
        margin-top: 0.8rem; } }
