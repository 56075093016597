// Object - page - top

.tp-news {
    padding-top: 9.5rem;
    //padding-bottom: 9.5rem;
    &-wrap {
        //width: 50.0rem;
        width: 100%;
        //margin-top: 3.5rem;
        margin-top: 1.5rem;
    }
    &__btn {
        margin: 3.9rem auto 0;
    }
    &-contents {
        //width: 50.0rem;
        width: 100%;
        //border-top: 1px solid #000;
        //margin-top: 2.2rem;
        margin-top: 0;
    }
    @include _mq-down(md) {
        padding-top: 7.5rem;
        //padding-bottom: 6.7rem;
        &-wrap {
            width: 100%;
            margin-top: 2.8rem;
        }
        &__btn {
            margin: 3.2rem auto 0;
        }
        &-contents {
            width: 100%;
        }
    }
}

.tp-facebook {
    margin-top: 5.5rem;
    width: 40.0rem;
    &-wrap {
        width: 40.0rem;
        height: 39.5rem;
        margin-top: 2.8rem;
    }
    .facebook-wrapper {
        width: 100%;
        height: 39.5rem;
        margin-top: 2.1rem;
        overflow: hidden;
    }
    &__btn {
        width: 50rem;
        height: auto;
        margin: 5rem auto 0;
        border: 1px solid #666;
        a {
            display: block;
            @include _on-event {
                opacity: 0.6;
            }
        }
    }
    @include _mq-down(md) {
        width: 100%;
        margin-top: 4.5rem;
        &-wrap {
            width: 100%;
            height: 40.2rem;
            margin-top: 2.0rem;
        }
        .facebook-wrapper {
            height: 40.2rem;
            margin-top: 2.2rem;
        }
        &__btn {
            width: 100%;
            margin: 3.5rem auto 0;
        }
    }
}

.tp-active {
    //background-color: #c2e7fa;
    padding: 9.5rem 0 0;
    @include _mq-down(md) {
        padding: 5rem 0 0;
    }
}
.tp-active-report {
    //display: flex;
    //justify-content: space-between;
    width: 100%;
    height: 20rem;
    background-image: $color-gradation;
    padding: 2rem 2rem 2rem 4.2rem;
    &__txtarea {
    }
    &__tit {
        color: #fff;
        font-size: 4.8rem;
        font-weight: 800;
        letter-spacing: 0.06em;
        margin-top: 1.9rem;
    }
    &__imgarea {
        display: flex;
        justify-content: center;
        .image {
            width: 18.0rem;
            height: 16.0rem;
            & + .image {
                margin-left: 2.0rem;
            }
        }
    }
    &__btn {
        margin: 3.1rem auto 0;
    }
    &__nendo {
        font-size: 1.6rem;
        letter-spacing: 0.06em;
        text-align: center;
        margin-top: 2rem;
        a {
            color: $color-brand;
            position: relative;
            padding: 0.2rem 1.5em 0.2rem 0.2rem;
            &::before {
                content: '＞';
                position: absolute;
                right: 0.1rem;
                top: 50%;
                transform: translateY(-50%);
            }
            &::after {
                content: '';
                width: 100%;
                height: 1px;
                background-color: $color-brand;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                transition: 0.4s ease;
            }
            @include _on-event {
                &::after {
                    opacity: 0;
                }
            }
        }
    }
    @include _mq-down(md) {
        height: auto;
        padding: 2.5rem;
        &__tit {
            font-size: 3.0rem;
            margin-top: 2.7rem;
            text-align: center;
            letter-spacing: 0.05em;
        }
        &__imgarea {
            justify-content: center;
            .image {
                width: 15.5rem;
                height: 13.7rem;
                & + .image {
                    margin-left: 1.5rem;
                }
            }
        }
    }
}

.tp-movie-wrap {
    width: 100%;
    padding-top: 6.2rem;
    padding-bottom: 6.2rem;
    background-color: #c2e7fa;
    @include _mq-down(md) {
        padding-top: 2.5rem;
        padding-bottom: 2.5rem;
    }
}

.tp-movie {
    //overflow: hidden;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    position: relative;
    //height: 27.3rem;
    //padding-bottom: 3.7rem;
    //box-sizing: content-box;
    &::before, &::after {
        content: '';
        width: 1.5rem;
        height: 105%;
        background-color: #c2e7fa;
        position: absolute;
        top: -2.5%;
        z-index: 20;
    }
    &::before {
        left: 0;
    }
    &::after {
        right: 0;
    }
    .item {
        width: 48.4rem;
        height: 27.3rem;
        padding: 0 1.5rem;
        box-sizing: content-box;
        iframe {
            width: 100%;
            height: 100%;
        }
    }
    @include _mq-up(md) {
        .slick-track {
            display: flex;
        }
    }
    /*.swiper-pagination-bullet {
        margin: 0 1.1rem 0 !important;
    }
    .swiper-pagination-bullet-active {
        background-color: #000;
    }*/
    .slick-dots {
        //bottom: -3.2rem;
        bottom: 0;
        li {
            width: 2.0rem;
            height: 2.0rem;
            margin: 0 0.5rem;
        }
    }
    .slick-dots li button::before {
        font-size: 1.0rem;
        line-height: 2.0rem;
        width: 2.0rem;
        height: 2.0rem;
    }
    &__btn {
        margin: 3.0rem auto 0;
    }
    @include _mq-down(md) {
        //margin-top: 2.5rem;
        margin-top: 0rem;
        //height: 18.3rem;
        //padding-bottom: 4.7rem;
        .item {
            width: 32.5rem;
            height: 18.3rem;
            padding: 0 1.5rem;
            box-sizing: content-box;
        }
        .slick-dots {
            //bottom: -4rem;
            bottom: 0;
        }
        .slick-slider {
            //margin-bottom: 3.0rem;
        }
        &__btn {
            margin: 2.0rem auto 0;
        }
    }
}

.tp-profile {
    padding-top: 10.5rem;
    padding-bottom: 9.5rem;
    @include _mq-down(md) {
        padding-top: 7.0rem;
        padding-bottom: 6.7rem;
    }
}

.tp-profile-btn {
    width: 100%;
    //height: 8rem;
    color: $color-brand;
    font-size: 3.0rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    text-align: center;
    padding: 2.4rem 0 2.6rem;
    border: 2px solid $color-brand;
    position: relative;
    margin-top: 5rem;
    cursor: pointer;
    &::after {
        content: '';
        width: 3.2rem;
        height: 3.2rem;
        background: url("../../assets/img/top/icon_profile_btn.svg") center center / contain no-repeat;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 3.6rem;
        transition: transform 0.4s ease;
    }
    &.isActive {
        &::after {
            transform: rotate(180deg);
        }
    }
    @include _mq-down(md) {
        font-size: 2.0rem;
        padding: 1.4rem 0 1.4rem;
        margin-top: 4.5rem;
        &::after {
            width: 1.8rem;
            height: 1.8rem;
            right: 1.3rem;
        }
    }
}

.tp-profile-detail-wrap {
    margin-top: 4.0rem;
    border-bottom: 1px solid #000;
    padding-bottom: 5.0rem;
    @include _mq-down(md) {
        margin-top: 5.0rem;
        padding-bottom: 2.7rem;
    }
}

.tp-profile-detail {
    @include _mq-up(md) {
        &.left {
            width: 45%;
            float: left;
        }
        &.right {
            width: 45%;
            float: right;
            margin-top: -0.4rem;
        }
    }
    &__tit {
        display: flex;
        align-items: center;
        .main {
            color: $color-brand;
            font-size: 2.2rem;
            font-weight: bold;
            letter-spacing: 0.1em;
        }
        .sub {
            font-size: 1.6rem;
            letter-spacing: 0.1em;
        }
    }
    &__txt {
        margin-top: 3.5rem;
    }
    &__things {
        padding-left: 5em;
        .thing {
            margin-left: -5em;
        }
    }
    .c-text {
        b {
            font-weight: normal;
            color: $color-brand;
        }
    }
    @include _mq-down(md) {
        &.right {
            margin-top: 2em;
        }
        &__tit {
            display: block;
            padding-bottom: 2.2rem;
            border-bottom: 1px solid rgba(#000,0.5);
            .main {
                font-size: 1.8rem;
                display: block;
            }
            .sub {
                font-size: 1.4rem;
                display: block;
                margin-top: 1.0rem;
            }
        }
        &__txt {
            margin-top: 1.8rem;
        }
    }
}

.tp-profile-head {
    margin-top: 10.8rem;
    &__txtarea {
        width: 62.7rem;
        .p-yellow-unbar-tit {
            font-size: 6.4rem;
        }
    }
    &__txt {
        margin-top: 5.5rem;
        text-align: justify;
    }
    &__img {
        width: 32.0rem;
        height: 32.0rem;
        border: 0.4rem solid $color-brand;
        border-radius: 50%;
        overflow: hidden;
        margin-top: -0.5rem;
    }
    .c-text {
        b {
            font-weight: bold;
            color: $color-brand;
        }
    }
    @include _mq-down(md) {
        margin-top: 4rem;
        &__txtarea {
            width: 100%;
            margin-top: 3.2rem;
            .p-yellow-unbar-tit {
                font-size: 3.2rem;
            }
        }
        &__txt {
            margin-top: 3rem;
        }
        &__img {
            width: 20.0rem;
            height: 20.0rem;
            margin: 0 auto;
        }
    }
}

.tp-profile-contents {
    margin-top: 6.0rem;
    .c-text {
        b {
            font-weight: bold;
            color: $color-brand;
        }
    }
    @include _mq-down(md) {
        margin-top: 4.0rem;
    }
}
.tp-profile-foot {
    margin-top: 9rem;
    &__img {
        width: 100%;
    }
    @include _mq-down(md) {
        margin-top: 4.4rem;
        &__img {
            margin-left: -2.5rem;
            margin-right: -2.5rem;
            width: 37.5rem;
        }
    }
}

.tp-vision {
    padding-top: 9.5rem;
    padding-bottom: 9.5rem;
    .c-text {
        b {
            font-weight: bold;
            color: $color-brand;
        }
    }
    &__dlbtn {
        margin: 7rem auto 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 2rem;
    }
    @include _mq-down(md) {
        padding-top: 5rem;
        //padding-bottom: 6.8rem;
        &__dlbtn {
            margin: 2rem auto 0;
        }
    }
}

.tp-vision-tit {
    color: $color-brand;
    font-size: 3.2rem;
    line-height: 4.2rem;
    font-weight: bold;
    letter-spacing: 0.05em;
    padding-left: 5rem;
    .num {
        font-size: 6.4rem;
        color: $color-orange;
        vertical-align: middle;
        margin-left: -5rem;
        padding-right: 1.5rem;
    }
    .title {
        vertical-align: middle;
        b {
            color: $color-green;
        }
    }
    & + p {
        margin-top: 3.5rem;
    }
    @include _mq-down(md) {
        font-size: 2.4rem;
        line-height: 3.6rem;
        padding-left: 4rem;
        .num {
            font-size: 4.8rem;
            margin-left: -4rem;
        }
        & + p {
            margin-top: 2.5rem;
        }
    }
}
.tp-vision-list-wrap {
    margin-top: 4.6rem;
    @include _mq-down(md) {
        margin-top: 3.0rem;
    }
}
.tp-vision-list {
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 4.0rem;
    counter-reset: number 0;
    border-bottom: 1px solid $color-brand;
    li {
        position: relative;
        padding: 0.95rem 0 0.95rem 3.4rem;
        border-top: 1px solid $color-brand;
        b {
            color: $color-brand;
        }
    }
    li:before {
        content: '';
        width: 2.4rem;
        height: 2.4rem;
        position: absolute;
        left: 0;
        top: 1.7rem;
    }
    @include _mq-down(md) {
        font-size: 1.8rem;
        line-height: 3.0rem;
        li {
            padding: 0.7rem 0 0.7rem 2.5rem;
        }
        li:before {
            width: 1.8rem;
            height: 1.8rem;
            top: 1.3rem;
        }
    }
    li:nth-child(1):before {
        background: url("../../assets/img/top/num01.svg") center center / contain no-repeat;
    }
    li:nth-child(2):before {
        background: url("../../assets/img/top/num02.svg") center center / contain no-repeat;
    }
    li:nth-child(3):before {
        background: url("../../assets/img/top/num03.svg") center center / contain no-repeat;
    }
    li:nth-child(4):before {
        background: url("../../assets/img/top/num04.svg") center center / contain no-repeat;
    }
    li:nth-child(5):before {
        background: url("../../assets/img/top/num05.svg") center center / contain no-repeat;
    }
    li:nth-child(6):before {
        background: url("../../assets/img/top/num06.svg") center center / contain no-repeat;
    }
    li:nth-child(7):before {
        background: url("../../assets/img/top/num07.svg") center center / contain no-repeat;
    }
    li:nth-child(8):before {
        background: url("../../assets/img/top/num08.svg") center center / contain no-repeat;
    }
    li:nth-child(9):before {
        background: url("../../assets/img/top/num09.svg") center center / contain no-repeat;
    }
    li:nth-child(10):before {
        background: url("../../assets/img/top/num10.svg") center center / contain no-repeat;
    }
    li:nth-child(11):before {
        background: url("../../assets/img/top/num11.svg") center center / contain no-repeat;
    }
    li:nth-child(12):before {
        background: url("../../assets/img/top/num12.svg") center center / contain no-repeat;
    }
    li:nth-child(13):before {
        background: url("../../assets/img/top/num13.svg") center center / contain no-repeat;
    }
    li:nth-child(14):before {
        background: url("../../assets/img/top/num14.svg") center center / contain no-repeat;
    }
    li:nth-child(15):before {
        background: url("../../assets/img/top/num15.svg") center center / contain no-repeat;
    }
}

.tp-vision-contents {
    //margin-top: 2.5rem;
    position: absolute;
    top: 39rem;
    left: 0;
    display: none;
    z-index: -1;
    width: 100%;
    @include _mq-down(md) {
        top: 37rem;
    }
}

.tp-pannel {
    background-image: $color-gradation;
    padding: 7.0rem 0;
    @include _mq-down(md) {
        padding: 2.5rem 0;
    }
}
.tp-pannel-contents {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.tp-pannel-card {
    width: 47.5rem;
    height: 20.0rem;
    padding: 2rem 3.3rem 2.8rem;
    display: flex;
    flex-flow: column;
    align-items: flex-end;
    &__tit {
        width: 26.0rem; //ボタンの横幅
        color: $color-brand;
        font-family: $font-family-tit;
        font-size: 3.0rem;
        font-weight: 800;
        height: 8.4rem;
        line-height: 4.2rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        b {
            color: $color-green;
        }
    }
    &__btn {
        margin-top: 1.8rem;
    }
    &.seminar {
        @include _mq-up(xxl) {
            background: url("../../assets/img/top/bnr_seminar_pc@2x.jpg") center center / cover no-repeat;
        }
        @include _mq-down(xxl) {
            background: url("../../assets/img/top/bnr_seminar_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(md) {
            background: url("../../assets/img/top/bnr_seminar_pc@2x.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/top/bnr_seminar_pc.jpg") center center / cover no-repeat;
            @include _mq-retina {
                background: url("../../assets/img/top/bnr_seminar_pc@2x.jpg") center center / cover no-repeat;
            }
        }
    }
    &.support {
        @include _mq-up(xxl) {
            background: url("../../assets/img/top/bnr_support_pc@2x.jpg") center center / cover no-repeat;
        }
        @include _mq-down(xxl) {
            background: url("../../assets/img/top/bnr_support_pc.jpg") center center / cover no-repeat;
        }
        @include _mq-down(md) {
            background: url("../../assets/img/top/bnr_support_pc@2x.jpg") center center / cover no-repeat;
        }
        @include _mq-down(sm) {
            background: url("../../assets/img/top/bnr_support_pc.jpg") center center / cover no-repeat;
            @include _mq-retina {
                background: url("../../assets/img/top/bnr_support_pc@2x.jpg") center center / cover no-repeat;
            }
        }
    }
    @include _mq-down(md) {
        width: 100%;
        height: 13.6rem;
        padding: 1.3rem 1rem 1rem 1rem;
        & + .tp-pannel-card {
            margin-top: 2.5rem;
        }
        &__tit {
            width: 20rem;
            font-size: 2.0rem;
            line-height: 2.8rem;
            height: 5.6rem;
        }
        &__btn {
            width: 20rem;
            margin-top: 0.8rem;
        }
    }
}
